/* class applies to select element itself, not a wrapper element */
.select-css {
  font-size: 16px;
  height: 3rem;
  font-family: sans-serif;
  font-weight: 700;
  color: #1bd8bc;
  padding: 0.6em 2.4em 0.5em 0.8em;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #1bd8bc;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #1d1d1d;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
    for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    
  */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%231bd8bc%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;

  transform: translateY(-30%);
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Hover style */
.select-css:hover {
  border-color: #00ffd9;
}
/* Focus style */
.select-css:focus {
  border-color: #08fdd8;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  /* box-shadow: 0 0 1px 3px #08fdd8;
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #08fdd8; */
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled="true"] {
  color: graytext;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%221bd8bc%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
}

.select-css:disabled:hover,
.select-css[aria-disabled="true"] {
  border-color: #08fdd8;
}

/* grid */

.gal-container {
  width: 100%;
  height: 100%;
  padding-left: 60px;
  left: 0;
  top: 0;
}

button {
  border: none;
  margin: 0;
}
button:focus {
  outline: none;
}

.gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  margin: 20px;
  overflow: hidden;
}
.gallery img {
  width: 100%;
  height: auto;
  transition: 500ms;
  margin-bottom: 10px;
  opacity: 1;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  transition: all 0.4s ease;
}

.box {
  transition: all 0.4s ease;
  position: relative;
  width: 100%;
}

.show1,
.show2 {
  opacity: 0;
  bottom: 10%;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: #08fdd8;
  font-size: 16px;
  font-family: "Gloria Hallelujah";
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #08fdd8;
  background-color: #1d1d1d77;
  margin-top: 25px;
  white-space: nowrap;
  transition: all 0.4s ease;
}
.show1 {
  left: 64%;
}
.show2 {
  left: 30%;
}
.prj {
  left: 50%;
  top: 0%;
  opacity: 0;
  width: 100%;
  position: absolute;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  text-align: center;
  color: #08fdd8;
  font-size: 2rem;
  font-family: "Gloria Hallelujah";
  text-decoration: none;
  color: #08fdd8;
  background: #000;
  background: -moz-linear-gradient(top, #000000aa 0%, #00000000 100%);
  background: -webkit-linear-gradient(top, #000000aa 0%, #00000000 100%);
  background: linear-gradient(to bottom, #000000aa 0%, #00000000 100%);
  padding-top: 10px;
  padding-bottom: 5px;
  white-space: nowrap;
  transition: all 0.4s ease;
}
.prj span {
  font-size: 1rem;
}

.show1:hover,
.show2:hover {
  background-color: #08fdd8;
  color: #1d1d1d;
}

.box:hover .image {
  filter: blur(1px);
  opacity: 0.7;
}
.gallery img:hover {
  filter: blur(1px);
  opacity: 0.7;
}

.box:hover .show1,
.box:hover .prj,
.box:hover .show2 {
  opacity: 1;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .prj {
    font-size: 1.5rem;
  }
  .prj span {
    font-size: 0.7rem;
  }
  .show1,
  .show2,
  .prj {
    padding: 5px 12px;
  }
}

@media screen and (max-width: 767px) {
  .gal-container {
    padding-left: 0px;
  }
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    padding: 10px 20px;
    padding-bottom: 60px;
  }
  .gallery .box {
    width: 100%;
    margin: auto;
  }
  .gallery img {
    opacity: 0.8;
  }
  .show1,
  .show2,
  .prj {
    opacity: 1;
    padding: 5px 12px;
  }
  .prj {
    font-size: 1.2rem;
  }
  .prj span {
    font-size: 0.5rem;
  }
  .show1 {
    left: 80%;
    bottom: 5%;
  }
  .show2 {
    left: 18%;
    bottom: 5%;
  }
  .gal-container .link,
  .gal-container i {
    max-height: 60px;
  }
}

@media screen and (max-width: 479px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .gallery div {
    margin: 0;
    width: 200px;
  }
}
